<template>
  <div
    :class="badgeClass(item)"
    :style="badgeStyle(item)"
  >
    <span
      class="product-label__badge_left"
      :style="borderStyle(item, 'Right')"
    />
    <span
      class="product-label__badge_right"
      :style="borderStyle(item, 'Left')"
    />
    <span class="product-label__badge_text">
      -{{ getDiscountPrice(product) }}%
      <span v-if="showDiscountDate">
        {{ $t('to') }} {{ getDiscountDay(product) }}.{{ getDiscountMonth(product) }}
      </span>
    </span>
  </div>
</template>

<script>
import { checkIfDateExpired } from 'theme/helpers/date';
import { getDiscountDate } from 'theme/helpers';

export default {
  name: 'DiscountBadge',
  props: {
    item: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    badgeClass () {
      return (item) => `product-label__badge product-label__badge-position-${item.position}`;
    },
    badgeStyle () {
      return (item) => item && item.color ? { backgroundColor: item.color } : {};
    },
    borderStyle () {
      return (item, side) => ({ [`border${side}`]: `var(--spacer-4) solid ${item.color}` });
    }
  },
  methods: {
    getDiscountPrice (product) {
      return product.special_price_discount ? Number(product.special_price_discount) : 0;
    },
    showDiscountDate () {
      return this.product && this.product.special_price_to_date && !checkIfDateExpired(this.product.special_price_to_date);
    },
    getDiscountDay (product) {
      return product.special_price_to_date ? getDiscountDate(product).date.day : null;
    },
    getDiscountMonth ({ special_price_to_date }) {
      return special_price_to_date && getDiscountDate(this.product).date.month;
    }
  }
};
</script>
